<template>
    <div
        class="flex flex-col justify-center items-center h-full gap-4"
        style="height: calc(100vh - var(--size-header-height) - var(--size-footer-height))"
    >
        <h1>You are being redirected...</h1>
        <p>Let's buzz you over to the login page</p>
    </div>
</template>

<script setup>
const auth = useAuthStore();

definePageMeta({ name: 'Sign In' });

onMounted(async () => {
    if (auth.loggedIn) {
        return (window.location.href = '/');
    }
    auth.signIn();
});
</script>

<style lang="scss" scoped></style>
